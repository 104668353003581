.popup-style {
    width: 100%;
    text-align: center
}

.login-popup {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.login-button {
    margin-right: 5px
}

.div-button-dropdown-label{
    display: flex;
    flex-direction: column; 
    align-items: center;
}

.div-button-dropdown-label .div-label-dropdown{
    display: flex; 
    align-items: center; 
    gap: 5%; 
    margin-bottom: 12px;
    margin-right: 32.8%;
}

.div-label-dropdown .label-for-dropdown{
    font-size: 15px;
    white-space: nowrap; 
}

.dropdown-listbox{
    min-width: 8.7rem;
}

.div-label-dropdown .dropdown-for-environment{
    min-width: 8.7rem;
}

.login-button-width{
    width: 8.7rem;
}

.logout-spinner{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.logout-spinner .fui-Spinner__label {
    color: white;
}
  